@font-face {
    font-family: 'ProximaNova-Regular';
    src: url('./fonts/Proxima_Nova/ProximaNova-Regular.ttf') format('truetype'),
  }
  
  @font-face {
    font-family: 'ProximaNova-Bold';
    src: url('./fonts/Proxima_Nova/ProximaNova-Bold.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'ProximaNova-Semibold';
    src: url('./fonts/Proxima_Nova/ProximaNova-Semibold.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'ProximaNova-Light';
    src: url('./fonts/Proxima_Nova/ProximaNova-Light.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'ProximaNova-Black';
    src: url('./fonts/Proxima_Nova/ProximaNova-Black.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'ProximaNova-Extrabld';
    src: url('./fonts/Proxima_Nova/ProximaNova-Extrabld.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'ProximaNova-ExtrabldIt';
    src: url('./fonts/Proxima_Nova/ProximaNova-ExtrabldIt.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'ProximaNova-LightIt';
    src: url('./fonts/Proxima_Nova/ProximaNova-LightIt.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'ProximaNova-SemiboldIt';
    src: url('./fonts/Proxima_Nova/ProximaNova-SemiboldIt.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'ProximaNova-Thin';
    src: url('./fonts/Proxima_Nova/ProximaNova-Thin.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'ProximaNova-ThinIt';
    src: url('./fonts/Proxima_Nova/ProximaNova-ThinIt.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-Black';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-Black.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-BlackItalic';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-BlackItalic.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-Bold';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-Bold.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-BoldItalic';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-BoldItalic.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-ExtraBold';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-ExtraBold.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-ExtraBoldItalic';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-ExtraBoldItalic.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-Italic';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-Italic.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-Medium';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-Medium.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-MediumItalic';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-MediumItalic.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-Regular';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-Regular.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-SemiBold';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-SemiBold.ttf') format('truetype')
  }
  
  @font-face {
    font-family: 'PlayfairDisplay-SemiBoldItalic';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-SemiBoldItalic.ttf') format('truetype')
  }
  